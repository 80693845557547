import React from 'react';
import styled from 'styled-components';
import SubscriptionForm from './client/components/SubscriptionForm';

const AppContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  color: #333;
  text-align: center;
`;

const App: React.FC = () => {
  return (
    <AppContainer>
      <Title>QDII 数据订阅</Title>
      <SubscriptionForm />
    </AppContainer>
  );
};

export default App;