import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const SubscriptionForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [minFinalReturn, setMinFinalReturn] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/subscribe', {
        email,
        scheduleTime,
        minFinalReturn: parseFloat(minFinalReturn) || 0
      });
      if (response.data.success) {
        alert('订阅成功！');
        setEmail('');
        setScheduleTime('');
        setMinFinalReturn('');
      } else {
        alert('订阅失败，请重试。');
      }
    } catch (error) {
      console.error('订阅错误：', error);
      alert('订阅时发生错误，请重试。');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label htmlFor="email">邮箱：</Label>
        <Input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="scheduleTime">发送时间：</Label>
        <Input
          id="scheduleTime"
          type="time"
          value={scheduleTime}
          onChange={(e) => setScheduleTime(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="minFinalReturn">最小最终收益（%）：</Label>
        <Input
          id="minFinalReturn"
          type="number"
          value={minFinalReturn}
          onChange={(e) => setMinFinalReturn(e.target.value)}
          min="0"
          step="0.01"
          required
        />
      </FormGroup>
      <Button type="submit">订阅</Button>
    </Form>
  );
};

export default SubscriptionForm;